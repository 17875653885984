const appConfig = {
  "name": "elo-admin",
  "version": "2.0.0",
  "stage": "develop",
  "regions": [
    {
      "name": "us-east-1",
      "isPrimaryRegion": true,
      "deployThisBuild": true,
      "regionDomain": "get.atlas-config-dev.mit.edu",
      "amplify": {
        "userPoolId": "us-east-1_14cFcpuS8",
        "userPoolWebClientId": "18105t88cm1t4e92gd7696l6a2",
        "oauth": {
          "domain": "atlas-auth-dev.mit.edu",
          "scope": [
            "profile",
            "openid",
            "elo/user",
            "elo/admin",
            "digital-id/search",
            "digital-id/user"
          ],
          "redirectSignIn": "https://elx-admin-dev.mit.edu/",
          "redirectSignOut": "https://elx-admin-dev.mit.edu/logout",
          "responseType": "token",
          "options": {
            "AdvancedSecurityDataCollectionFlag": false
          }
        }
      },
      "signInProvider": "Touchstone",
      "api": {
        "domain": "api-dev.mit.edu",
        "paths": {
          "elo": "elo-v2",
          "digital-id": "digital-id-v1"
        }
      },
      "mapBoxKey": "pk.eyJ1IjoibWl0LXByb2R1Y3QtbWFwcyIsImEiOiJja2htMXpsYWowNjBkMnlxMWdtYTdjcmFjIn0.2JgsLng6v3GIHt34-aeEjw"
    }
  ]
};
export default appConfig;